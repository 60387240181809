<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="activity-page">
    <b-card class="" title="PDF Forms">
      <b-card-text>
        This page shows all PDF forms.
      </b-card-text>
    </b-card>
    <b-card no-body class="mb-0">
      <div class="row px-2 pt-1 justify-content-end mb-2">
        <section class="col-3">
          <label>Search</label>
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              @keydown.enter="getMoreForms(1)"
              class="d-inline-block" placeholder="Search form title..."
            />
            <b-input-group-append>
              <b-button @click="getMoreForms(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section class="col-3">
          <label>Resident ID</label>
          <b-input-group>
            <b-form-input
              v-model="filters.identifier"
              @keydown.enter="getMoreForms(1)"
              class="d-inline-block" placeholder="Search resident id..."
            />
            <b-input-group-append>
              <b-button @click="getMoreForms(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section class="col-3" v-if="$can('ReadAll', 'PDFForm')">
          <label>Created by</label>
          <model-list-select :list="users"
                             @input="getMoreForms(1)"
                             :class="{'disabled': users.length === 0}"
                             v-model="filters.user_uid"
                             option-value="uid"
                             option-text="name"
                             clearable
                             placeholder="Select user"/>
        </section>

        <div class="ui-spacer"></div>

        <section class="col-3 d-flex justify-content-end align-items-end">
          <b-button @click="clearFilters()">Clear filters</b-button>
        </section>
      </div>
      <section>
        <b-table
          class="data-table"
          :items="forms"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>
          <template #cell(actions)="data">
            <b-dropdown v-if="$can('Delete', 'PDFForm') || data.item.file"
              class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn" v-if="data.item.file" @click="viewPdf(data.item)">
                View Form
              </b-dropdown-item-button>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Delete', 'PDFForm')"
                                      @click="showDeleteModal(data.item)">
                Delete Form
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreForms" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import PDFFormService from '@/services/PDFFormService';
import UserService from "@/services/UserService";
import { ModelListSelect } from 'vue-search-select'

export default {
  components: {
    ModelListSelect,
  },
  props: {},
  data() {
    return {
      headers: [
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'identifier',
          label: 'Resident ID',
        },
        {
          key: 'created_at',
          label: 'Created At',
        },
        {
          key: 'user.name',
          label: "User"
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      forms: [],
      users: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 15,
        search: '',
        identifier: '',
        user_uid: '',
      },
      total_count: 0,
      timeout: null,
    };
  },
  mounted() {
    this.getForms();
    if (this.$can('Read', 'User') && this.$can('ReadAll', 'PDFForm')) {
      this.getUsers();
    }
  },
  methods: {
    getMoreForms(page) {
      this.filters.page = page - 1 // api index starts at 0, bootstrap starts at 1 lol
      this.getForms()
    },
    async getForms() {
      try {
        const res = await PDFFormService.list(this.filters)
        this.forms = res.data.data
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch(err) {
        const res = err.response
        let errorText = 'Could not get PDF Forms, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    async getUsers() {
      UserService.getUsers({
        page: 0,
        page_size: 30,
      }).then(res => {
        this.users = res.data.data;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get users, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    async viewPdf(form) {
      try {
        const res = await PDFFormService.getFile(form.id)
        const blob = new Blob([res.data]);
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl
        link.download = form.file.filename;
        link.click();
      } catch(err) {
        const res = err.response
        let errorText = 'Could not get PDF, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    async deleteForm(form) {
      try {
        await PDFFormService.delete(form.id)
        this.$toast.success('PDF Form deleted successfully', {
          toastClassName: ['toast-std', 'success-toast'],
        })
        if(this.total_count - 1 <= this.filters.page_size * this.filters.page) {
          this.filters.page -= 1
          this.currentPage -= 1
        }
        await this.getForms()
      } catch(err) {
        const res = err.response
        let errorText = 'Could not delete PDF Form, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    showDeleteModal(form) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this form? This action cannot be undone.', {
        title: 'Delete Form',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
          if (value) {
            this.deleteForm(form)
          }
      })
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
        identifier: '',
      }

      this.getForms();
    },
  },
};
</script>
