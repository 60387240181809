import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/users';

export default {
  getUsers(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  createUser(user) {
    return axios.post(`${baseUrl}`, user);
  },
  deleteUser(uid) {
    return axios.delete(`${baseUrl}/${uid}`);
  },
  getUser(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  updateUser(uid, userDto) {
    return axios.put(`${baseUrl}/${uid}`, userDto);
  },
  updateUserPassword(uid, passwordDto) {
    return axios.put(`${baseUrl}/${uid}/password`, passwordDto);
  },
};
