import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/pdf-forms'

export default {
  list(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  get(id) {
    return axios.get(`${baseUrl}/${id}`)
  },
  delete(id) {
    return axios.delete(`${baseUrl}/${id}`)
  },
  getFile(id) {
    return axios.get(`${baseUrl}/${id}/file`, {
      responseType: "blob",
    })
  },
}
